import { useEffect, useRef, useState } from 'react';
import { dark, light } from './Theme';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import NotFound from './pages/NotFound';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { refetchAlertState, themeState } from 'store/Atoms';
import Login from 'pages/Login';
import PwReset from 'pages/PwReset';
import PwChange from 'pages/PwChange';
import { fetchLogout } from 'service/common';
import Container from 'components/template/Container';
import Dashboard from 'pages/Dashboard';
// import usePushNotification from 'hooks/usePushNotification';
//headers 설정 문제
import { EventSourcePolyfill, NativeEventSource } from 'event-source-polyfill';
import ModalAlert from 'components/molecule/ModalAlert';
import Loader from 'components/atom/Loader';
import useLoading from 'hooks/useLoading';
import { useQueryClient } from '@tanstack/react-query';

function App() {
  const queryClient = useQueryClient();
  const setRefetchAlert = useSetRecoilState(refetchAlertState);
  const [isLoading, setIsLoading] = useLoading();
  const userToken = sessionStorage.getItem('token');
  //   const { fireNotificationWithTimeout } = usePushNotification();

  const fixedTheme = sessionStorage.getItem('theme'); // 세션에 최근 저장된 테마값
  const [themeMode, setThemeMode] = useRecoilState(themeState);
  const theme = themeMode === 'dark' ? dark : light;

  const [alertData, setAlertData] = useState({});

  const connectSSE = () => {
    const EventSource = EventSourcePolyfill || NativeEventSource;

    let eventSource: any = new EventSource('/api/env/alert/subscribe', {
      headers: {
        Authorization: sessionStorage.getItem('token'),
      },
    });

    eventSource.onopen = () => {
      // 연결 시 할 일
      console.log('연결 완료');
    };

    //실시간 알람 구독
    eventSource.addEventListener('subscribe-alert', (e: any) => {
      console.log('subscribe-alert', e);
    });

    // 실시간 알림 받기
    eventSource.addEventListener('alert', (e: any) => {
      console.log('알람발생');
      // 타임아웃으로 알람 재요청 (바로 요청시 알람 없는 현상)
      setTimeout(() => {
        setRefetchAlert({ refetch: true });
      }, 500);
      // queryClient.invalidateQueries({ queryKey: ['alert'] }); // 읽지않은 알림조회

      // console.log('alert', e);
      const data = JSON.parse(e?.data);
      // console.log(data);
      setAlertData(data);

      //알림
      // fireNotificationWithTimeout('알림이 발생하였습니다.', 5000, {
      //   body: '카운트:' + e.data,
      // });
    });
    eventSource.onerror = (e: any) => {
      // 종료 또는 에러 발생 시 할 일
      eventSource.close();

      // Maximum Call Stack Size Exceeded 에러
      // 재귀함수 - 호출 스택이 비워지는 시간을 주어 에러를 방지
      setTimeout(connectSSE, 0);

      // connectSSE(); // re-connection....

      if (e.error) {
        // 에러 발생 시 할 일
        console.log(e.error, 'e');
      }

      if (e.target.readyState === EventSource.CLOSED) {
        // 종료 시 할 일
      }
    };
    // } catch (e) {
    //   console.log(e);
    // }
    //  };
  };

  //SSE 통신
  useEffect(() => {
    // 로그인 성공 이후
    if (userToken) {
      connectSSE();
    }
  }, [userToken]);

  // 세션값에 맞게 테마 셋팅
  useEffect(() => {
    if (fixedTheme) {
      setThemeMode(fixedTheme);
    }
  }, [fixedTheme]);

  const routers = createBrowserRouter(
    [
      {
        path: '/',
        element: sessionStorage.getItem('token') ? <Container /> : <Login />, // -> <> <Header/> <Container/> </>
        errorElement: <NotFound />,
        children: [
          {
            index: true,
            element: <Dashboard />,
          },
          {
            path: 'dashboard',
            element: <Dashboard />,
          },
        ],
      },
      {
        path: 'pwReset',
        element: <PwReset />,
      },
      {
        path: 'pwChange',
        loader: () => {
          if (userToken) {
            return true;
          } else {
            console.log('로그인 정보가 없습니다.');
            window.location.href = '/';
            return false;
          }
        },
        element: <PwChange />,
      },
    ],
    { basename: '/' },
  );

  return (
    <>
      {/* Theme */}
      <ThemeProvider theme={theme}>
        {/* react-query API 요청 미완료시 Loader */}
        <Loader />

        {/* 라우터 */}
        <RouterProvider router={routers} />
        {/* 알림 */}
        <ModalAlert alertData={alertData} setAlertData={setAlertData} />
      </ThemeProvider>
    </>
  );
}

export default App;
