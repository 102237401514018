import React, { useEffect, useMemo, useState } from 'react';
import PopupFrame from './PopupFrame';
import { useRecoilState, useRecoilValue } from 'recoil';
import { detInfoState, loaderState, siteInfoState } from 'store/Atoms';
import Table from 'components/atom/Table';

import { getDetAnomaly } from 'service/env';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

// 센서 차트 내에서 해당장치의 이상 데이터를 조회함
// 광센서1,2,3,방식전위1,2,초음파1
function PopupChartError({ close }) {
  const queryClient = useQueryClient();
  const { siteId } = useRecoilValue(siteInfoState);
  const detInfo = useRecoilValue(detInfoState);
  const detId = detInfo?.detId;
  const detType = detInfo?.detType; // optical, ac/dc, waves
  const detUnit = detInfo?.unit;
  const [detAnomaly, setDetAnomaly] = useState<any>(null); // 이상 데이터 -> 요청 후에는 배열 []

  //   이상 데이터 조회 pending 유무 - 로더 표시
  const [pending, setPending] = useState(false);

  useEffect(() => {
    // 공통 R-Query 로더 없이 요청하기
    const source = axios.CancelToken.source(); // Axios 취소시 필요한 token
    const getData = async () => {
      let timer = setTimeout(() => {
        setPending(true); // 0.5초 후에 로더 시작
      }, 500);

      try {
        let result = await getDetAnomaly({ detId, source }); // 데이터 요청
        setDetAnomaly(result || []); // 데이터 State 저장 (요청 후에는 배열 형태)
      } catch (e) {
        console.log(e);
      } finally {
        clearTimeout(timer); // 타이머 클리어
        setPending(false); // 요청완료 후 로더 종료
      }
    };
    getData();

    // axios 요청 취소
    return () => {
      source.cancel('Component unmounted');
    };
  }, []);

  //   console.log('is pending..', pending);
  //   console.log('detAnomaly..', detAnomaly);

  return (
    <PopupFrame title="이상 데이터 보기" close={close} $width={700}>
      <div style={{ width: '100%', minHeight: '344px', border: '1px solid #e5e5e5' }}>
        <Table
          tbodyId="chartErrorList"
          caption={'이상 데이터 목록'}
          colgroup={['10%', '20%', '40%', 'auto']}
          //   height={'calc(100% - 138px)'}
          //   tbodyHeight={168}
          tbodyHeight={280}
          thead={
            <tr>
              <th scope={'col'}>No.</th>
              <th scope={'col'}>장치번호</th>
              <th scope={'col'}>일시</th>
              <th scope={'col'}>데이터</th>
            </tr>
          }
          tbody={
            <>
              {/* 로더 부분 */}
              {pending && (
                <tr>
                  <td></td>
                  <td></td>
                  <td>데이터 요청중...</td>
                  <td></td>
                </tr>
              )}
              {/* detAnomaly 데이터 배열 존재할때 */}
              {detAnomaly?.map((ele: any, idx) => (
                <tr key={'chartError:' + idx}>
                  <td>{idx + 1}</td>
                  <td>센서{ele.sensor}</td>
                  <td>{ele.date}</td>
                  <td>{ele.value}</td>
                </tr>
              ))}
              {/* 요청 완료 후 detAnomaly [빈 배열]이면 */}
              {!pending && detAnomaly?.length < 1 && (
                <tr className="active">
                  <td></td>
                  <td></td>
                  <td>데이터가 없습니다.</td>
                  <td></td>
                </tr>
              )}
            </>
          }
        />
      </div>
    </PopupFrame>
  );
}

export default PopupChartError;
