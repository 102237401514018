import React, { useState, useEffect } from 'react';
import { useGetQueryData, useQueryState } from 'hooks/reactQuery/useQueryState';
import { LineChart, Line, AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Area, Legend } from 'recharts';
import { useRecoilValue } from 'recoil';
import { getDetChart, getMapData } from 'service/env';
import { detInfoState, siteInfoState } from 'store/Atoms';
import styled from 'styled-components';
import Select from 'components/atom/Select';
import useInputChange from 'hooks/useInputChange';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { findMaxWithReduce, findMinWithReduce } from 'util/global';
import PopupChartError from 'popups/PopupChartError';

export const ChartSt = {
  Wrap: styled.div`
    width: 100%;
    height: calc(100% - 52px - 56px); // -Header, -TabArea
    padding: 20px 24px;
    box-sizing: border-box;
    overflow: auto;
  `,
  TitleWrap: styled.div`
    margin-bottom: 12px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .titleWrap {
      display: flex;
      gap: 14px;
      .title {
        font-weight: 600;
        font-size: 16px;
      }
      .errorBtn {
        font-size: 13px;
        color: #242628;
        padding: 3px 6px;
        border-radius: 2px;
        border: 1px solid #d5d5d5ff;
        /* background-color: rgb(240, 26, 26); */
        /* color: #fff; */
        cursor: pointer;
        &:hover {
          transition: all.2s;
          /* box-shadow: 0px 4px 4px 0px #ff190037; */
          border: 1px solid #e21818;
          color: #e21818;
        }
      }
    }
  `,
  Box: styled.div`
    width: 100%;
    height: 240px;
    padding: 16px 24px;
    box-sizing: border-box;
    border: 1px solid #d5d5d5;
    border-radius: 4px;
    overflow: auto;
    .recharts-wrapper {
      .recharts-surface {
        // yAxis text 잘림 현상
        overflow: visible;
      }
    }
  `,
  TooltipWrap: styled.div`
    padding: 11px 26px;
    border: 1px solid #d5d5d5;
    box-shadow: 2px 2px 7px 0px #00000014;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    gap: 6px;
    .label {
      font-size: 12px;
      color: #797a7c;
    }
    .value {
      font-weight: 600;
    }
    &.error {
      border-color: #f01a1a;
      .value {
        color: #f01a1a;
      }
    }
  `,
  FakeChartWrap: styled.div`
    width: 100%;
    height: 100%;
    position: relative;

    .nullText {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: #00000054;
      position: absolute;
      top: 0;
      z-index: 1;
    }
  `,
};

const data = [
  {
    date: 'date 01:00',
    uv: 0,
    pv: 2400,
    amt: 2400,
  },
  {
    date: 'date 02:00',
    uv: 0,
    pv: 1398,
    amt: 2210,
  },
  {
    date: 'date 03:00',
    uv: 0,
    pv: 9800,
    amt: 2290,
  },
  {
    date: 'date 04:00',
    uv: 0,
    pv: 3908,
    amt: 2000,
  },
  {
    date: 'date 05:00',
    uv: 0,
    pv: 4800,
    amt: 2181,
  },
  {
    date: 'date 06:00',
    uv: 0,
    pv: 3800,
    amt: 2500,
  },
  {
    date: 'date 07:00',
    uv: 0,
    pv: 4300,
    amt: 2100,
  },
];

function DashboardChart() {
  const { siteId } = useRecoilValue(siteInfoState);
  const detInfo = useRecoilValue(detInfoState);
  const detId = detInfo?.detId;
  const detType = detInfo?.detType; // optical, ac/dc, waves
  const detUnit = detInfo?.unit;

  const [openChartErr, setOpenChartErr] = useState(false); // 이상 데이터 목록 팝업 오픈

  const [rechartData, setRechartData] = useState<any[]>([]);
  const [rechartData2, setRechartData2] = useState<any[]>([]); // 두번째 차트 : ac/dc 경우 ac 데이터용

  const [minNum1, setMinNum1] = useState(0);
  const [minNum2, setMinNum2] = useState(0);
  const [maxNum1, setMaxNum1] = useState(0);
  const [maxNum2, setMaxNum2] = useState(0);

  const [minNum, setMinNum] = useState<any[]>(['auto', 'auto']);

  const [xInterval, setXInterval] = useState(0); //x축 값 show 개수 조정

  const initBeforeDays = detType === 'optical' ? 24 : 1;
  const [beforeDays, setBeforeDays] = useState(1); // 1, 7, 30
  const [form, setForm, onChange] = useInputChange({});

  // 차트 데이터 조회 (detId)
  const { data: chartData } = useQueryState(['det', 'chart', { detId, beforeDays }], () => getDetChart({ detId, beforeDays }), {
    enabled: true,
  });

  // 당일 차트 데이터
  // 1) 지도 정보 (쿼리 키로 접근)
  //   const mapData: any = useGetQueryData(['map', { siteId }]);

  // 2) 지도 정보 조회
  //   const mapData: any = useQuery({
  //     queryKey: ['map', { siteId, increaseIdx }],
  //     queryFn: () => getMapData(siteId, increaseIdx),
  //     enabled: !!siteId,
  //   });
  //   const todayChartData = mapData?.data?.siteDets?.find((ele) => ele.detId === detId)?.chart ;

  // 차트명
  const chartTypeKo = detType === 'optical' ? ['광센서'] : detType === 'waves' ? ['초음파'] : detType === 'ac/dc' ? ['방식전위 DC', '방식전위 AC'] : [];

  console.log('detType', detType);

  // 차트용 데이터 만들기
  useEffect(() => {
    // 현재 션택된 감지장치 타입
    const detTypeArr = detType?.split('/')?.reverse() || []; // ['optical'],['waves'],['dc','ac']
    // 당일 차트 데이터 및 다른 날짜 차트 데이터 따로 요청
    //  let orgChartData = beforeDays === 0 ? todayChartData : chartData;
    let orgChartData = chartData;
    let orgChartArr: any[] = detType === 'waves' ? orgChartData?.[detTypeArr[0]]?.[0]?.value : orgChartData?.[detTypeArr[0]] || [];

    let waveArr: any[] = [];

    if (detType === 'waves') {
      orgChartData?.['waves']?.map((item) => {
        waveArr.push(...item.value);
      });
      console.log(waveArr);
      orgChartArr = waveArr;
    }

    function checkZero(value) {
      if (value === null) {
        return null;
      }
      if (String(value)) {
        if (Number(value) === 0) {
          //  console.log(Number(value));
          return '0';
        }
        return Number(value);
      }
    }
    // 첫번째 차트
    function pushNewData(ele, valueArr, newChartData) {
      const promise = new Promise<void>((resolve, reject) => {
        setTimeout(() => {
          valueArr.push(ele.value || 0); // 차트1 value 배열

          //  let obj = {};
          //  let keys = Object.keys(ele);
          //  console.log(keys);
          //  keys.map((key) => {
          //    let value = ele[key];

          //    obj[key] = value;
          //  });
          //  console.log(obj);

          newChartData.push({
            date: ele.date || ele.idx, // x축 dataKey
            error: ele.error, // 에러문구
            value: checkZero(ele.value) || checkZero(ele.sensorValue) || null, // null -> 0 처리
            value2: checkZero(ele.value2) || null,
            value3: checkZero(ele.value3) || null,
            value4: checkZero(ele.value4) || null,
            value5: checkZero(ele.value5) || null,
          });

          resolve();
        }, 0);
      });

      return promise;
    }

    // [interval] x축 값 show 개수 조정 (0 : All)
    function findXInterval(chart) {
      let arrLength = chart?.length; // 차트 배열 길이
      const xInterval = arrLength / 5 > 1 ? Math.floor(arrLength / 5) : 0;
      return xInterval;
    }

    // 차트 데이터 정제 후 결과 리턴 Async 함수
    async function parallel(array) {
      let valueArr: any = []; // 차트 value 배열 (최소,최대값 구하기용)
      let newChartData: any = [];

      try {
        // 1. 데이터 정제 -> 새 배열로 저장하는 Promise 배열
        const promises = array?.map(async (ele) => await pushNewData(ele, valueArr, newChartData));
        // 2. 모든 Promise 루프 기다림.
        await Promise.all(promises);
      } catch (error) {
        console.log('실패', error);
      }

      // * 최소값 구하기
      const minNum: any = findMinWithReduce(valueArr);
      const maxNum: any = findMaxWithReduce(valueArr);
      console.log('all done :)', minNum, maxNum);

      return {
        newChartData,
        minNum,
        maxNum,
      };
    }

    // 차트 관련 데이터 셋팅
    async function chartSetting() {
      // * 첫번째 차트 - 방식전위 DC | 광센서 | 초음파
      const result = await parallel(orgChartArr); // 데이터 정제하기
      const { newChartData, minNum, maxNum } = result;
      setXInterval(findXInterval(newChartData)); // x축 값 개수 조정
      setRechartData(newChartData); // 차트1 데이터
      setMinNum1(minNum);
      setMaxNum1(maxNum);

      // * 두번째 차트 - 방식전위 AC
      if (detTypeArr?.[1]) {
        let orgChartArr2 = orgChartData?.[detTypeArr?.[1]];
        const result = await parallel(orgChartArr2); // 데이터 정제하기
        const { newChartData, minNum, maxNum } = result;
        setRechartData2(newChartData);
        setMinNum2(minNum);
        setMaxNum2(maxNum);
      } else {
        setRechartData2([]); // * 두번째 차트 초기화
      }
    }

    if (orgChartArr?.length > 0) {
      //  if (chartSuccess) {
      chartSetting(); // 차트 관련 데이터 셋팅
    } else {
      setRechartData([]); // * 첫번째 차트 초기화
      setRechartData2([]); // * 첫번째 차트 초기화
    }
  }, [
    beforeDays,
    detType,
    chartData,
    //  todayChartData
  ]);

  // 차트 날짜 옵션 - 당일,일주일,한달
  useEffect(() => {
    if (form?.daySelect) {
      setBeforeDays(Number(form?.daySelect));
    }
  }, [form]);

  console.log(minNum1, maxNum1);
  console.log(minNum2, maxNum2);

  // * minNum이 음수일때 그라데이션 스타일 * //
  // <YAxis domain=[0,'최소값'] /> <Area baseValue={'최소값'}/>

  //   const yAxisDomain = undefined;
  //  const yAxisDomain = detType === 'waves' ? [-0.1, 0.1] : minNum1 < 0 ? [0, minNum1] : undefined;
  const yAxisDomain = minNum1 < 0 ? [0, minNum1] : [minNum1 - 1, maxNum1 + 1];
  const yAxisDomain_2 = minNum2 < 0 ? [0, minNum2] : [minNum2 - 1, maxNum2 + 1];

  //   console.log(rechartData);
  return (
    <>
      <ChartSt.Wrap>
        <ChartSt.TitleWrap>
          <div className="titleWrap">
            <p className="title">{chartTypeKo[0]}</p>
            <button className="errorBtn" onClick={() => setOpenChartErr(true)}>
              이상 데이터 보기
            </button>
          </div>
          <div className="selectBox">
            {/* 광센서 - 시간별 조회 */}
            {detType === 'optical' && (
              <Select
                name="daySelect"
                width={132}
                option={[
                  { value: 1, text: '당일', active: true },
                  { value: 2, text: '최근 1주일', active: false },
                  { value: 3, text: '최근 한달', active: false },
                ]}
                onChange={onChange}
              />
            )}
            {/* 방식전위 */}
            {detType === 'ac/dc' && (
              <Select
                name="daySelect"
                width={132}
                option={[
                  { value: 1, text: '당일', active: true },
                  { value: 7, text: '최근 1주일', active: false },
                  { value: 30, text: '최근 한달', active: false },
                ]}
                onChange={onChange}
              />
            )}
            {/* 초음파 */}
            {detType === 'waves' && (
              <Select
                name="daySelect"
                width={132}
                option={[
                  { value: 1, text: '최근 1개', active: true },
                  { value: 5, text: '최근 5개', active: false },
                  { value: 10, text: '최근 10개', active: false },
                ]}
                onChange={onChange}
              />
            )}
          </div>
        </ChartSt.TitleWrap>

        {/* <TestChart /> */}

        <ChartSt.Box>
          {rechartData?.length > 0 ? (
            <LineChart width={653} height={200} data={rechartData} margin={{ top: 0, right: 10, left: 5, bottom: 15 }}>
              <defs>
                <linearGradient id="colorBlue" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#0076FF" stopOpacity={0.34} />
                  <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0} />
                </linearGradient>
              </defs>

              {/* CHECK: defaulProps 이슈  */}
              <XAxis
                interval={xInterval}
                tickFormatter={(value, index) => {
                  return '';
                }}
                dataKey="date"
                stroke={'#9099A7'}
                fontSize={12}
                fontWeight={500}
                axisLine={false}
                tickLine={true}
                tick={<CustomizedTick />}
                //  tickLine={detType !== 'waves' && true}
                //  tick={detType !== 'waves' && <CustomizedTick />}
              />
              <YAxis unit={` ${detUnit}`} stroke={'#9099A7'} fontSize={12} fontWeight={500} axisLine={false} tickLine={false} domain={yAxisDomain} />

              <CartesianGrid stroke={'#D4DDE9'} horizontal={true} vertical={false} />

              <Legend height={26} verticalAlign="top" align="right" content={<div style={{ fontSize: '12px', fontWeight: '500', color: '#9099A7' }}>단위({detUnit})</div>} />

              {/* 커스텀 툴팁 */}
              <Tooltip content={<CustomTooltip detType={detType} />} />

              {/* <Area
                type="monotone"
                name={detType !== 'waves' ? '1번' : ' '}
                dataKey={'value'}
                stroke="#0076FF"
                fillOpacity={1}
                fill="url(#colorBlue)"
                unit={` ${detUnit}`}
                dot={<CustomizedDot color="#1682FF" />}
                activeDot={<CustomizedActiveDot />}
                baseValue={minNum1}
              /> */}

              <Line
                type="monotone"
                name={detType !== 'waves' ? '1번' : ' '}
                dataKey={'value'}
                stroke="#0076FF"
                //   strokeWidth={1}
                fillOpacity={1}
                fill="url(#colorBlue)"
                unit={` ${detUnit}`}
                dot={<CustomizedDot />}
                activeDot={<CustomizedActiveDot color="#1682FF" />}
                //   baseValue={minNum1}
              />
              {/* 광센서 일때만 */}
              {detType === 'optical' && (
                <>
                  <Line
                    type="monotone"
                    name="2번"
                    dataKey={'value2'}
                    //   strokeWidth={1}
                    stroke="#00ffcc"
                    fillOpacity={1}
                    fill="url(#colorBlue)"
                    unit={` ${detUnit}`}
                    dot={<CustomizedDot />}
                    activeDot={<CustomizedActiveDot color="#00ffcc" />}
                    //  baseValue={minNum1}
                  />
                  <Line
                    type="monotone"
                    name="3번"
                    dataKey={'value3'}
                    //   strokeWidth={1}
                    stroke="#ffc400"
                    fillOpacity={1}
                    fill="url(#colorBlue)"
                    unit={` ${detUnit}`}
                    dot={<CustomizedDot />}
                    activeDot={<CustomizedActiveDot color="#ffa600" />}
                    //  baseValue={minNum1}
                  />
                  <Line
                    type="monotone"
                    name="4번"
                    dataKey={'value4'}
                    //   strokeWidth={1}
                    stroke="#c300ff"
                    fillOpacity={1}
                    fill="url(#colorBlue)"
                    unit={` ${detUnit}`}
                    dot={<CustomizedDot />}
                    activeDot={<CustomizedActiveDot color="#c300ff" />}
                    //  baseValue={minNum1}
                  />
                  <Line
                    type="monotone"
                    name="5번"
                    dataKey={'value5'}
                    //   strokeWidth={1}
                    stroke="#ff7e06"
                    fillOpacity={1}
                    fill="url(#colorBlue)"
                    unit={` ${detUnit}`}
                    dot={<CustomizedDot />}
                    activeDot={<CustomizedActiveDot color="#ff7e06" />}
                    //  baseValue={minNum1}
                  />
                </>
              )}
            </LineChart>
          ) : (
            <FakeChart />
          )}
        </ChartSt.Box>

        {/* 두번째 차트 데이터 있을 때 */}
        {detType === 'ac/dc' ? (
          <>
            <ChartSt.TitleWrap style={{ marginTop: '28px' }}>
              <div className="titleWrap">
                <p className="title">{chartTypeKo[1]}</p>
              </div>
            </ChartSt.TitleWrap>

            <ChartSt.Box>
              {rechartData2.length > 0 ? (
                <AreaChart width={653} height={200} data={rechartData2} margin={{ top: 0, right: 15, left: 0, bottom: 15 }}>
                  <defs>
                    <linearGradient id="colorBlue" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor="#0076FF" stopOpacity={0.34} />
                      <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0} />
                    </linearGradient>
                  </defs>

                  <XAxis
                    interval={xInterval}
                    tickFormatter={(value, index) => {
                      return '';
                    }}
                    dataKey="date"
                    stroke={'#9099A7'}
                    fontSize={12}
                    fontWeight={500}
                    axisLine={false}
                    tickLine={true}
                    tick={<CustomizedTick />}
                  />
                  <YAxis unit={` ${'V'}`} stroke={'#9099A7'} fontSize={12} fontWeight={500} axisLine={false} tickLine={false} domain={yAxisDomain_2} />

                  <CartesianGrid stroke={'#D4DDE9'} horizontal={true} vertical={false} />

                  <Legend height={26} verticalAlign="top" align="right" content={<div style={{ fontSize: '12px', fontWeight: '500', color: '#9099A7' }}>단위({'V'})</div>} />

                  {/* 커스텀 툴팁 필요 */}
                  <Tooltip content={<CustomTooltip />} />

                  <Area type="monotone" name=" " dataKey={'value'} stroke="#0076FF" fillOpacity={1} fill="url(#colorBlue)" unit={` ${'V'}`} dot={<CustomizedDot />} activeDot={<CustomizedActiveDot />} baseValue={minNum2} />
                </AreaChart>
              ) : (
                <FakeChart />
              )}
            </ChartSt.Box>
          </>
        ) : null}
      </ChartSt.Wrap>

      {/* 이상 데이터 목록 팝업 */}
      {openChartErr && <PopupChartError close={() => setOpenChartErr(false)} />}
    </>
  );
}

export default DashboardChart;

// x 값 커스터마이징
export const CustomizedTick = (props) => {
  const { x, y, stroke, payload } = props;

  //   console.log('Tick', payload);
  const valueArr = String(payload?.value)?.split(' ');

  let dataLength = 6;
  let isLastTick = props?.index === dataLength;
  let xPosition = isLastTick ? x - 15 : x;

  return (
    /* x,y는 상대좌표 (width, height) px */
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={20} fontSize={12} fontWeight={500} textAnchor="start" fill="#9099A7">
        <tspan textAnchor="middle" x="0">
          {valueArr?.[0]}
        </tspan>
        <tspan textAnchor="middle" x="0" dy="14">
          {valueArr?.[1]}
        </tspan>
      </text>
    </g>
  );
};

// 차트 - 점 : 정상 데이터 (dot(상시표출) 또는 activeDot(마우스 오버시 표출) 파라미터로 전달 가능)
// onClick 기능 가능?
const CustomizedActiveDot = (props) => {
  const { cx, cy, stroke, payload, value, color } = props;

  //   console.log(value);
  //   console.log(payload);

  // 값이 없을 경우 찍지 않음.
  if (value === null) {
    return null;
  }
  if (value?.[1] === null) {
    return null;
  }

  /* 에러 기준은 데이터 status값 등으로 변경 */
  if (payload.error) {
    return null;
  }

  return (
    <svg x={cx - 10} y={cy - 10} width={24} height={24} fill="none" viewBox="0 0 24 24">
      <circle opacity="0.15" cx="12" cy="12" r="12" fill={color || '#1682FF'} />
      <circle opacity="0.24" cx="12" cy="12" r="8" fill={color || '#1682FF'} />
      <circle cx="12" cy="12" r="4" fill={color || '#1682FF'} />
    </svg>
  );
};

// 차트 - 점 : 에러 데이터 (dot(상시표출))
const CustomizedDot = (props) => {
  const { cx, cy, stroke, payload, value } = props;

  // 값이 없을 경우 찍지 않음.
  if (value === null) {
    return null;
  }
  if (value?.[1] === null) {
    return null;
  }

  /* 에러 기준은 데이터 status값 등으로 변경 */
  if (payload.error) {
    return (
      <svg x={cx - 10} y={cy - 10} width={24} height={24} fill="none" viewBox="0 0 24 24">
        <circle opacity="0.15" cx="12" cy="12" r="12" fill="#F01A1A" />
        <circle opacity="0.24" cx="12" cy="12" r="8" fill="#F01A1A" />
        <circle cx="12" cy="12" r="4" fill="#F01A1A" />
      </svg>
    );
  }

  return null;
};

// 툴팁
const CustomTooltip = (props) => {
  const { active, payload, label, detType } = props;
  //   console.log(payload);

  const isError = payload[0]?.payload?.error;

  if (active && payload && payload.length) {
    return (
      <ChartSt.TooltipWrap className={isError && 'error'}>
        <p className="label">{`${label}`}</p>
        {/* {detType !== 'waves' && <p className="label">{`${label}`}</p>} */}
        {payload?.map((payload, idx) => {
          if (isError) {
            return (
              <p key={'error-' + idx} className="value">
                {isError && `${payload?.payload?.error} (${payload?.value} ${payload?.unit})`}
              </p>
            );
          }
          if (!isError) {
            return <p key={'normal-' + idx} className="value">{`${payload?.name} ${payload?.value} ${payload?.unit}`}</p>;
          }
        })}
        {/* <p className="value">
          {isError && `${payload[0]?.payload?.error} (${payload[0]?.value} ${payload[0]?.unit})`}
          {!isError && `${payload[0]?.value} ${payload[0]?.unit}`}
        </p> */}
      </ChartSt.TooltipWrap>
    );
  }

  return null;
};

const FakeChart = () => {
  return (
    <>
      <ChartSt.FakeChartWrap>
        <div className="nullText">데이터가 없습니다.</div>
        <AreaChart width={653} height={200} data={data} margin={{ top: 0, right: 15, left: 0, bottom: 15 }}>
          <defs>
            <linearGradient id="colorBlue" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#0076FF" stopOpacity={0.34} />
              <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0} />
            </linearGradient>
          </defs>

          <XAxis
            tickFormatter={(value, index) => {
              return '';
            }}
            dataKey="date"
            stroke={'#9099A7'}
            fontSize={12}
            fontWeight={500}
            axisLine={false}
            tickLine={true}
            tick={<CustomizedTick />}
          />
          <YAxis dataKey={'uv'} stroke={'#9099A7'} fontSize={12} fontWeight={500} axisLine={false} tickLine={false} />

          <CartesianGrid stroke={'#D4DDE9'} horizontal={true} vertical={false} />
        </AreaChart>
      </ChartSt.FakeChartWrap>
    </>
  );
};
