import React, { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

function Timer({ mm = '10', ss = '0' }) {
  const queryClient = useQueryClient();

  const [minutes, setMinutes] = useState(parseInt(mm));
  const [seconds, setSeconds] = useState(parseInt(ss));

  useEffect(() => {
    console.log('rerender?');
  }, []);

  useEffect(() => {
    const countdown = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(countdown);

          // TODO:
          queryClient.resetQueries();
          setMinutes(10);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => clearInterval(countdown);
  }, [minutes, seconds]);

  return (
    <>
      ({minutes}:{seconds < 10 ? `0${seconds}` : seconds})
    </>
  );
}

export default Timer;
